<template>
  <div v-if="loading" class="grid">
    <b-skeleton position="is-centered" height="30" width="100%" />
    <b-skeleton position="is-centered" height="30" width="100%" />
    <b-skeleton position="is-centered" height="30" width="100%" />
    <b-skeleton position="is-centered" height="30" width="100%" />
    <b-skeleton position="is-centered" height="30" width="100%" />
    <b-skeleton position="is-centered" height="30" width="100%" />
  </div>
  <div v-else-if="myContact.id && !edit">
    <div class="columns">
      <b-field class="column" :label="$t('contact.last')">
        {{ myContact.full_name }}
      </b-field>
      <b-field class="column" :label="$t('contact.relation')">
        {{ myContact.relationship || '-' }}
      </b-field>
      <b-field class="column" :label="$t('contact.phone')">
        {{ myContact.phone_number }}
      </b-field>
      <b-field class="column" :label="$t('contact.mobile')">
        {{ myContact.mobile_phone_number }}
      </b-field>
    </div>
  </div>
  <form ref="form" v-else-if="!myContact.id || edit" class="columns">
    <Field
      :label="$t('contact.last')"
      class="column"
      v-model="myContact.last_name"
      @input="onInput"
      :maxlength="80"
      required
    />
    <Field
      :label="$t('contact.name')"
      class="column"
      v-model="myContact.first_name"
      @input="onInput"
      :maxlength="60"
      required
    />
    <Field
      :label="$t('contact.middle')"
      class="column"
      v-model="myContact.middle_name"
      @input="onInput"
      :maxlength="60"
    />
    <Autocomplete
      :label="$t('contact.relation')"
      field="label"
      model="id"
      :value="realtionshipStr"
      class="column"
      :data="relationships"
      @select="(relation) => (this.myContact.relationship = relation)"
    />
    <!-- @select="onSelect" -->
    <Phone
      class="column"
      v-model="myContact.phone_number"
      :label="$t('contact.phone')"
      @input="onInput"
      required
    />
    <Phone
      class="column"
      v-model="myContact.mobile_phone_number"
      :label="$t('contact.mobile')"
      @input="onInput"
      :differentTo="myContact.phone_number"
    />
  </form>
</template>

<script>
import Autocomplete from '@/components/Autocomplete.vue';
import Field from '@/components/Field.vue';
import Phone from '@/components/Phone/PhonePicker.vue';
export default {
  components: {
    Autocomplete,
    Field,
    Phone
  },
  computed: {
    relationships() {
      return [
        { id: 'SON', label: this.$t('relationship.SON') },
        { id: 'DAUGHTER', label: this.$t('relationship.DAUGHTER') },
        { id: 'HUSBAND', label: this.$t('relationship.HUSBAND') },
        { id: 'WIFE', label: this.$t('relationship.WIFE') },
        { id: 'BROTHER', label: this.$t('relationship.BROTHER') },
        { id: 'SISTER', label: this.$t('relationship.SISTER') },
        { id: 'GRANDSON', label: this.$t('relationship.GRANDSON') },
        { id: 'GRANDDAUGHTER', label: this.$t('relationship.GRANDDAUGHTER') },
        { id: 'NEPHEW', label: this.$t('relationship.NEPHEW') },
        { id: 'NIECE', label: this.$t('relationship.NIECE') },
        { id: 'COUSIN', label: this.$t('relationship.COUSIN') },
        { id: 'FRIEND', label: this.$t('relationship.FRIEND') },
        { id: 'NONE', label: this.$t('relationship.NONE') }
      ];
    }
  },
  data() {
    const myContact = this.value || {};
    this.setRealtionshipStr(myContact);
    return {
      myContact,
      realtionshipStr: ''
    };
  },
  methods: {
    onSelect(id) {
      console.log('onSelect', id);
      this.myContact.relationship = id;
      this.onInput();
    },
    onInput() {
      this.$emit('input', this.myContact);
    },
    setRealtionshipStr(myContact = this.myContact) {
      this.realtionshipStr = myContact.relationship
        ? this.$t(`relationship.${myContact.relationship}`)
        : '';
    },
    validate() {
      let htmlValidator = true;
      const form = this.$refs.form;
      if (form) {
        htmlValidator = form.checkValidity();
        if (!htmlValidator) form.reportValidity();
      }
      return htmlValidator;
    }
  },
  watch: {
    value(value) {
      this.myContact = value;
      this.setRealtionshipStr();
    }
  },
  props: {
    edit: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    value: { default: () => {} }
  }
};
</script>

<style lang="sass" scoped>
.b-skeleton
  margin-top: .5rem
.grid
  display: grid
  grid-gap: 10px
  grid-template-columns: repeat(3, 1fr)
</style>
