<template>
  <section>
    <Card :loading="loading.get">
      <template #header>
        <StepCardHeader
          v-model="current"
          :steps="steps"
          :onlyFirstAllowed="oParticipant.id ? false : true"
          returnRoute="/data/participants"
        />
      </template>
      <General-step v-if="current == 1" :participant="oParticipant" @change="onChange" />
      <Location-step v-if="current == 2" :participantId="participantId" @change="onAddressChange" />
      <Contacts-step v-if="current == 3" :participantId="participantId" />
      <Mailing-step v-if="current == 4" :participant="oParticipant" />
    </Card>
  </section>
</template>

<script>
import Card from '@/components/Card.vue';
import ContactsStep from './Steps/Contacts.vue';
import GeneralStep from './Steps/General/General.vue';
import LocationStep from './Steps/Location/Location.vue';
import MailingStep from './Steps/Mailing.vue';
import StepCardHeader from '@/components/StepCardHeader.vue';
import { Permissions } from '@/utils/Secure';
const { Participants, ParticipantAddresses } = Permissions;

export default {
  components: {
    Card,
    ContactsStep,
    GeneralStep,
    LocationStep,
    MailingStep,
    StepCardHeader
  },
  async created() {
    if (this.participantId) await this.getParticipant();
    if (!this.oParticipant.participant_first_name) {
      this.$router.push('/data/participants/add');
    }
  },
  data() {
    const participantId = Number(this.$route.params.participantId || 0);
    return {
      participantId,
      loading: { get: false, save: false },
      oParticipant: { id: participantId },
      steps: [
        { name: 'global.general', permission: Participants.update },
        { name: 'global.location', permission: ParticipantAddresses.read }
        // 'global.contacts',
        // 'global.mailing.short'
      ],
      current: this.$route.meta?.current || 1
    };
  },
  computed: {},
  methods: {
    async getParticipant() {
      this.loading.get = true;
      try {
        const { data } = await this.Api.get(`participants/${this.participantId}?is_active=2`);
        this.oParticipant = data;
      } catch (error) {
        console.error(error);
      }
      this.loading.get = false;
    },
    onChangeStep(step = 1) {
      const routes = ['', '/location', '/contacts', '/mailing'];
      if (this.oParticipant.id) {
        this.$router.replace({
          path: `/data/participants/${this.oParticipant.id}${routes[step - 1]}`
        });
        this.current = step;
      }
    },
    onAddressChange(address) {
      this.oParticipant.participant_address_id = address.id;
    },
    onChange(participant) {
      this.oParticipant = participant;
      this.participantId = participant.id;
    },
    goBack() {
      // TODO validate history to stay
      this.$router.go(-1);
    }
  }
};
</script>
<style lang="sass" scoped>
.card
  margin: 0
  min-height: calc(100% - 90px)
  ::v-deep
    .card-header
      padding: 0
      justify-content: flex-end
  .disabled
    cursor: not-allowed
</style>
