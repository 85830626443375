<template>
  <section class="mailing">
    <div class="flex">
      <div class="image">
        <img src="@/assets/img/mailing.svg" alt="" srcset="" />
      </div>
      <div class="m-container">
        <Inside-card :title="$t('global.mailing.label')" :padding-bottom="30">
          <AddressPicker ref="addressPicker" v-model="address" :pickPosition="false" />
        </Inside-card>
      </div>
    </div>
    <save-button @click="onSave" :loading="loading.save" />
  </section>
</template>

<script>
import AddressPicker from '@/components/Address/AddressPicker.vue';
import InsideCard from '@/components/InsideCard.vue';
import saveButton from './saveButton.vue';
import { toast } from '@/utils/dialog';
export default {
  components: {
    AddressPicker,
    InsideCard,
    saveButton
  },
  mounted() {
    this.$store.dispatch('setBreadcumbs', this.breadcumbs);
  },
  computed: {
    breadcumbs() {
      return [
        'menu.dat',
        this.participant.id ? 'participant.edit' : 'participant.new',
        'global.mailing.label'
      ];
    }
  },
  data() {
    return {
      loading: { save: false, get: false },
      address: {
        address: this.participant.participant_mailing_address,
        address_1: this.participant.participant_mailing_address_1,
        city: this.participant.participant_mailing_city,
        state: this.participant.participant_mailing_state,
        zipcode: this.participant.participant_mailing_zipcode
      }
    };
  },
  methods: {
    async onSave() {
      this.loading.save = true;
      if (this.validate()) {
        await this.updateAddress();
      }
      this.loading.save = false;
    },
    async updateAddress() {
      try {
        const { participant, address } = this;
        await this.Api.patch(`/participants/${participant.id}/set_mailing_address`, {
          mailing_address: address.address,
          mailing_address_1: address.address_1,
          mailing_state: address.state,
          mailing_city: address.city,
          mailing_zipcode: address.zipcode
        });
        toast('success', this.$t('messages.updated'), 5000);
      } catch (error) {
        console.error(error);
      }
    },
    validate() {
      let htmlValidator = true;
      if (this.$refs.addressPicker) htmlValidator = this.$refs.addressPicker.validate();
      return htmlValidator;
    }
  },
  watch: {
    participant(P) {
      this.address = {
        address: P.participant_mailing_address,
        address_1: P.participant_mailing_address_1,
        city: P.participant_mailing_city,
        state: P.participant_mailing_state,
        zipcode: P.participant_mailing_zipcode
      };
    }
  },
  props: {
    participant: {
      type: Object,
      required: true,
      default: () => {}
    }
  }
};
</script>

<style lang="sass" scoped>
.mailing
  justify-content: center
.image
  width: 300px
.m-container
  max-width: 900px
  margin: 0 5%
</style>
