import { FundingSourceSelector } from '@/components/Selectors';
import Phone from '@/components/Phone/PhonePicker.vue';
import saveButton from '../saveButton.vue';
import { toast, confirm } from '@/utils/dialog';
import { clientCodeRegex } from '@/utils/RegexValidations';
import {
  AddressPicker,
  Autocomplete,
  DateConstraint,
  DatePicker,
  DeliverRestrictions,
  Facility,
  Field,
  Gender,
  iSwitch,
  PhoneCreator,
  PicturePicker
} from '../../../../components';

export default {
  components: {
    AddressPicker,
    Autocomplete,
    PicturePicker,
    DateConstraint,
    DatePicker,
    DeliverRestrictions,
    Facility,
    Field,
    FundingSourceSelector,
    Gender,
    MySwitch: iSwitch,
    Phone,
    PhoneCreator,
    saveButton
  },
  mounted() {
    this.$store.dispatch('setBreadcumbs', [
      'menu.dat',
      this.paramsParticipantId ? 'participant.edit' : 'participant.new',
      'global.general'
    ]);
    this.updateDeliveryRestrictions();
    this.getAddress();
    this.getPhones();
    if (this.participant.participant_full_name)
      this.participantFullName = this.participant.participant_full_name;
  },
  data() {
    const oParticipant = {
      participant_is_active: 1,
      participant_date_of_birth: null,
      participant_address_state: 'CA',
      participant_space_type: 'AMB',
      participant_participant_status_id: 1,
      ...this.participant
    };
    const loadingAddress = !!this.$route?.params?.participantId;
    return {
      address: { id: null, state: 'CA' },
      loading: false,
      hasDeliveryRestriction: false,
      deliveryRestrictions: { delivery_end_time: 0, delivery_start_time: 0 },
      loadingAddress,
      toggleActiveLoading: false,
      oParticipant,
      phonesLoading: false,
      participantFullName: null,
      phones: [],
      focusPhoneIndex: null,
      clientCodeRegex
    };
  },
  computed: {
    DOB() {
      const dateStr = this.oParticipant.participant_date_of_birth;
      const date = dateStr ? new Date(dateStr) : null;
      return date;
    },
    deliverRestrictions() {
      return {
        start_time: this.oParticipant.participant_delivery_start_time,
        end_time: this.oParticipant.participant_delivery_end_time
      };
    },
    defaultDate() {
      return this.$moment().add(-65, 'Y').toDate();
    },
    maxDate() {
      return this.$moment().add(-55, 'years').toDate();
    },
    minDate() {
      return this.$moment().add(-105, 'years').toDate();
    },
    paramsParticipantId() {
      return this.$route.params.participantId;
    },
    statutes() {
      return [
        { id: 1, label: this.$t('participant.status.act') },
        { id: 2, label: this.$t('participant.status.exp') },
        { id: 3, label: this.$t('participant.status.vac') },
        { id: 4, label: this.$t('participant.status.hos') }
      ];
    },

    spaceTypes() {
      return [
        { id: 'AMB', label: this.$tc('condNeed.AMB', 2) },
        { id: 'WCH', label: this.$tc('condNeed.WCH', 2) },
        { id: 'WCT', label: this.$tc('condNeed.WCT', 2) }
      ];
    },
    WCH_types() {
      return [
        { id: 'STANDARD', label: this.$t('condNeed.WCHType.stand') },
        { id: 'ELECTRIC', label: this.$t('condNeed.WCHType.elec') },
        { id: 'HI-RAISE', label: this.$t('condNeed.WCHType.HiRa') }
      ];
    },

    state_code() {
      const { oParticipant: P } = this;
      return (
        P.participant_address_state &&
        (P.participant_address_state.code || P.participant_address_state)
      );
    },
    onPhonesLoading() {
      if (this.phones.length > 5) return this.phones.length;
      return 5;
    }
  },
  methods: {
    updateDeliveryRestrictions() {
      this.deliveryRestrictions = {
        delivery_end_time: this.oParticipant.participant_delivery_end_time || 0,
        delivery_start_time: this.oParticipant.participant_delivery_start_time || 0
      };
      this.hasDeliveryRestriction =
        !!this.deliveryRestrictions.delivery_end_time ||
        !!this.deliveryRestrictions.delivery_start_time;
    },
    hanldePermanentRestriction(data) {
      const { delivery_start_time, start_time, delivery_end_time, end_time } = data || {};
      this.deliveryRestrictions.delivery_start_time = delivery_start_time || start_time || 0;
      this.deliveryRestrictions.delivery_end_time = delivery_end_time || end_time || 0;
    },
    async toggleActive(is_active, isOverride) {
      this.toggleActiveLoading = true;
      try {
        await this.Api.patch(
          `participants/${this.oParticipant.id}/is_active/toggle${isOverride ? '/override' : ''}`
        );
        this.oParticipant.participant_is_active = is_active;
      } catch (error) {
        if (error) {
          return await new Promise((resolve) => {
            confirm({
              title: this.$t('participant.activeOverride'),
              cancelText: this.$t('confirm.no'),
              confirmText: this.$t('confirm.yes'),
              onCancel: () => {
                const value = this.oParticipant.participant_is_active;
                this.oParticipant.participant_is_active = !value;
                setTimeout(() => {
                  this.oParticipant.participant_is_active = value;
                }, 100);
                this.toggleActiveLoading = false;
                resolve(false);
              },
              onConfirm: () => this.toggleActive(is_active, true)
            });
          });
        }
      }
      this.toggleActiveLoading = false;
    },
    onFacilityChange(facility) {
      const id = facility.id || 0;
      this.oParticipant.participant_facility_id = id;
      if (id) this.oParticipant.participant_facility_type = facility.facility_type;
      else this.oParticipant.participant_facility_type = null;
    },
    dateParser(date) {
      return date;
    },
    async getAddress() {
      try {
        const participantId = this.paramsParticipantId || this.oParticipant.id;
        const addressId = this.oParticipant.participant_address_id;
        if (participantId && addressId) {
          const { data } = await this.Api.get(
            `/participants/${participantId}/addresses/${addressId}?is_active=${
              this.oParticipant.participant_is_active ? '1' : '0'
            }`
          );
          this.address = data[0];
          this.loadingAddress = false; //isRequired
        }
      } catch (error) {
        console.error(error);
        this.loadingAddress = false; //isRequired
      }
    },
    async getPhones(loading = true) {
      if (loading) this.phonesLoading = true;
      this.phones = [];
      try {
        const participantId = this.paramsParticipantId || this.oParticipant.id;
        if (participantId) {
          const { data } = await this.Api.get(
            `participants/${participantId}/phone_numbers?sort=-is_default`
          );
          if (data.length > 0) {
            this.phones = data;
            this.sortPhones();
          }
        }
      } catch (error) {
        console.error(error);
      }
      if (loading) this.phonesLoading = false;
    },
    prepareData() {
      const { oParticipant: P } = this;
      let deliveryRestrictions = { delivery_end_time: null, delivery_start_time: null };
      if (this.hasDeliveryRestriction) {
        deliveryRestrictions = {
          delivery_end_time: this.deliveryRestrictions?.delivery_end_time || null,
          delivery_start_time: this.deliveryRestrictions?.delivery_start_time || null
        };
      }
      return {
        cbla: P.participant_cbla || 0,
        client_code: P.participant_client_code,
        client_type_id: 2,
        date_of_birth: P.participant_date_of_birth,
        email: P.participant_email,
        facility_id: P.participant_facility_id,
        first_name: P.participant_first_name,
        funding_source_id: P.participant_funding_source_id,
        gender: P.participant_gender,
        is_blind: P.participant_is_blind || 0,
        is_deaf: P.participant_is_deaf || 0,
        is_mailing_address_principal: P.participant_is_mailing_address_principal,
        last_name: P.participant_last_name,
        mentalillness: P.participant_mentalillness || 0,
        middle_name: P.participant_middle_name,
        mobile_phone_number: P.participant_mobile_phone_number,
        needs_companion: P.participant_needs_companion || 0,
        needs_translator: P.participant_needs_translator || 0,
        needs_vehicle_ramp: P.participant_needs_vehicle_ramp || 0,
        needs_walker: P.participant_needs_walker || 0,
        participant_status_id: P.participant_participant_status_id,
        permanent_comment: P.participant_permanent_comment,
        phone_number: P.participant_phone_number,
        policy_number: P.participant_policy_number,
        safe_for_female: P.participant_safe_for_female || 0,
        space_type: P.participant_space_type,
        wch_type: P.participant_wch_type,
        ...deliveryRestrictions,
        ...this.prepareMailing()
      };
    },
    prepareMailing() {
      let mailing = {};
      const { oParticipant: P } = this;
      if (P.participant_is_mailing_address_principal)
        mailing = {
          mailing_address: this.address.address,
          mailing_address_1: this.address.address_1,
          mailing_state: this.address.state,
          mailing_city: this.address.city,
          mailing_zipcode: this.address.zipcode
        };
      return mailing;
    },
    async saveParticipant() {
      const { id } = this.oParticipant;
      const pData = this.prepareData();
      const method = id ? 'put' : 'post';
      const url = `/participants${id ? `/${id}` : ''}`;
      return await this.Api[method](url, pData);
    },
    async savePicture() {
      const fileName = `${this.oParticipant.id}.png`;
      await this.$refs.picturePicker.save({ fileName });
    },
    async saveAddress() {
      try {
        const { address } = this;
        const { id } = address;
        const params = {
          name: address.name || 'MyAddressName',
          address: address.address,
          address_1: address.address_1,
          state: address.state,
          city: address.city,
          zipcode: address.zipcode,
          latitude: address.latitude || 0,
          longitude: address.longitude || 0,
          gate_code: address.gate_code || null,
          formatted_address: address.formatted_address
        };
        if (!id) params.is_default = 1;
        const { data } = await this.Api[id ? 'put' : 'post'](
          `/participants/${this.oParticipant.id}/addresses${id ? `/${id}` : ''}`,
          params
        );
        this.address.id = data.id; //!check this out
        this.oParticipant.participant_address_id = data.id;
      } catch (error) {
        console.error(error);
      }
    },
    async onSave() {
      if (this.validate()) {
        this.loading = true;
        try {
          const { data } = await this.saveParticipant();
          this.oParticipant.id = data.id;
          this.participantFullName = data.full_name;
          await this.saveAddress();
          await this.savePicture();
          this.updateMailing(data);
          toast('success', this.$t('messages.saved'), 5000);
          this.$emit('change', this.oParticipant);
          this.$router.replace({
            path: `/data/participants/${this.oParticipant.id}`
          });
        } catch (error) {
          console.error(error);
        }
        this.loading = false;
      }
    },
    updateMailing({
      mailing_address,
      mailing_address_1,
      mailing_state,
      mailing_city,
      mailing_zipcode
    }) {
      this.oParticipant.participant_mailing_address = mailing_address;
      this.oParticipant.participant_mailing_address_1 = mailing_address_1;
      this.oParticipant.participant_mailing_state = mailing_state;
      this.oParticipant.participant_mailing_city = mailing_city;
      this.oParticipant.participant_mailing_zipcode = mailing_zipcode;
    },
    hasPendingPhones() {
      const hasPending = !!this.phones.filter((p) => p.isPendingToSave)[0];
      if (hasPending) toast('warning', this.$t('contact.phoneValidateSaved'), 5000);
      return hasPending;
    },
    validate() {
      if (this.$refs.form && !this.$refs.form.checkValidity()) {
        this.$refs.form.reportValidity();
        return false;
      }
      if (!this.$refs.addressPicker.validate()) return false;
      if (this.hasPendingPhones()) return false;
      return true;
    },

    sortPhones() {
      const defaultPhones = this.phones.filter((phone) => phone.is_default == true);
      const normalPhones = this.phones.filter((phone) => phone.is_default == false);
      this.phones = defaultPhones.concat(normalPhones);
    },
    handleAddPhone(type) {
      this.focusPhoneIndex = this.phones.length;
      this.phones.push({
        contact_name: this.oParticipant.participant_full_name,
        index: this.focusPhoneIndex,
        is_default: false,
        isPendingToSave: true,
        number_type: type === 'MOBILE' ? 'mobile' : 'land',
        phone_number: ''
      });
    },

    handleRemovePhone(value) {
      if (value.index && !value.id) {
        return this.deletePhone(value);
      }
      confirm({
        message: this.$t('contact.phoneDelete', { phone: value.phone_number }),
        cancelText: this.$t('confirm.no'),
        confirmText: this.$t('button.delete'),
        onConfirm: async () => {
          this.deletePhone(value);
        }
      });
    },

    async deletePhone(value, isOverride = false) {
      if (!value.id) {
        this.phones.forEach((e, i) => {
          if (e.phone_number === value.phone_number) {
            this.phones.splice(i, 1);
          }
        });
        return;
      }
      const participantId = this.paramsParticipantId || this.oParticipant.id;
      this.phonesLoading = true;
      try {
        await this.Api.delete(
          `/participants/${participantId}/phone_numbers/${value.id}${isOverride ? '/override' : ''}`
        );

        toast('success', this.$t('contact.phoneDeleteSuccess'), 5000);
        this.getPhones();
      } catch (error) {
        if (error.data.status === 'Phone Number Unique') {
          confirm({
            message: this.$t('contact.phoneDeleteUnique', {
              phone:
                value.number_type === 'mobile'
                  ? this.$t('contact.mobile').toLowerCase()
                  : this.$t('contact.landline').toLowerCase()
            }),
            cancelText: this.$t('confirm.no'),
            confirmText: this.$t('button.delete'),
            onConfirm: async () => {
              this.deletePhone(value, true);
            }
          });
        }
      }
      this.sortPhones();
      this.phonesLoading = false;
    },

    handleSetDefaultPhone(value) {
      if (!value.id) return toast('warning', this.$t('contact.defaultPhoneWarning'), 5000);
      confirm({
        message: this.$t('contact.phoneSetDefault', { phone: value.phone_number }),
        cancelText: 'No',
        confirmText: 'Ok',
        onConfirm: () => {
          this.updateDefaultPhone(value.id);
        }
      });
    },
    async updateDefaultPhone(id) {
      this.phonesLoading = true;
      try {
        const participantId = this.paramsParticipantId || this.oParticipant.id;
        await this.Api.patch(`/participants/${participantId}/phone_numbers/${id}`);
        this.getPhones(false);
        toast('success', this.$t('messages.updated'), 5000);
      } catch (error) {
        console.error(error);
      }
      this.phonesLoading = false;
    }
  },
  watch: {
    'oParticipant.participant_space_type'(value) {
      if (value === 'WCH') this.oParticipant.participant_needs_vehicle_ramp = 1;
      else this.oParticipant.participant_needs_vehicle_ramp = 0;
    },
    'oParticipant.participant_address_id'(value) {
      if (value) this.getAddress();
    },
    participant(participant) {
      this.oParticipant = participant;
      this.updateDeliveryRestrictions();
    },
    '$route.path'(value) {
      if (value === '/data/participants/add') {
        this.$store.dispatch('setBreadcumbs', ['menu.dat', 'participant.new', 'global.general']);
      } else {
        this.$store.dispatch('setBreadcumbs', ['menu.dat', 'participant.edit', 'global.general']);
      }
    }
  },
  props: {
    participant: {
      type: Object,
      required: true
    }
  }
};
