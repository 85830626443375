import AddressPicker from '@/components/Address/AddressPicker.vue';
import AddressViewer from '@/components/Address/AddressViewer.vue';
import PicturePicker from '@/components/PicturePicker.vue';
import { Button, Field } from '../../../../components';
import { toast, confirm } from '@/utils/dialog';
import defaultImage from '@/assets/img/addressLocation.svg';
import saveButton from '../saveButton.vue';
import { Permissions } from '@/utils/Secure';

export default {
  components: {
    AddressPicker,
    AddressViewer,
    Field,
    Button,
    PicturePicker,
    saveButton
  },
  created() {
    this.unsubscribe = this.$store.subscribe(({ type }, { map }) => {
      if (type == 'map/response') {
        if (map?.response?.action == 'position') this.setPosition(map?.response?.data?.latLng);
      }
    });
  },
  mounted() {
    this.$store.dispatch('setBreadcumbs', this.breadcumbs);
    this.updateMapBreadcumbs();
    this.getAddresses();
  },
  destroyed() {
    this.updateMapBreadcumbs(true);
    this.unsubscribe();
  },
  computed: {
    Permission() {
      return Permissions.ParticipantAddresses;
    },
    breadcumbs() {
      return [
        'menu.dat',
        this.participantId ? 'participant.edit' : 'participant.new',
        'global.location'
      ];
    }
  },
  data() {
    return {
      address: {},
      gate_code: null,
      addresses: [],
      setDefaultImg: '',
      defaultAddress: {},
      image: '',
      defaultImage,
      loadingAdvanced: {
        default: null,
        delete: null
      },
      loading: {
        getAddresses: true,
        save: false,
        edit: false
      },
      radioId: 0,
      unsubscribe: null,
      comparedAddress: {},
      isSameAsCompared: true,
      pictureIndex: 1,
      picturePickerKey: 1,
      isNewImage: false
    };
  },
  methods: {
    emitChange(address) {
      this.$emit('change', address);
    },
    confirmSave() {
      confirm({
        title: this.$t('confirms.continue'),
        message: this.$t('confirms.changeAddress'),
        cancelText: 'No',
        confirmText: 'Ok',
        onConfirm: () => {
          this.onSave(true);
        }
      });
    },
    async getAddresses() {
      this.loading.getAddresses = true;
      try {
        const { data } = await this.Api.get(`/participants/${this.participantId}/addresses`);
        this.addresses = data;
        this.updateDefaultAddress();
        this.onEdit(this.defaultAddress);
      } catch (error) {
        console.error(error);
      }
      this.loading.getAddresses = false;
    },
    async onEdit(address) {
      this.picturePickerKey = this.address.pictureId || this.address.id;
      this.isNewImage = false;
      try {
        this.address = { ...address };
        this.comparedAddress = { ...address };
      } catch (error) {
        console.log(error);
      }
    },
    onRemove(address) {
      confirm({
        title: this.$t('participant.addressRemoveConfirmation'),
        cancelText: this.$t('confirm.no'),
        confirmText: this.$t('button.delete'),
        onConfirm: async () => {
          this.deleteAddress(address);
        }
      });
    },
    async deleteAddress(address, isOverride = false) {
      this.loadingAdvanced.delete = address.id;
      try {
        await this.Api.delete(
          `/participants/${this.participantId}/addresses/${address.id}${
            isOverride ? '/override' : ''
          }`
        );
        this.addresses.forEach((e, i) => {
          if (e.id === address.id) {
            this.addresses.splice(i, 1);
          }
        });
        toast('success', this.$t('global.addressDeleted'), 3000);
      } catch (error) {
        if (error.data.status === 'Address Is Scheduled Future') {
          confirm({
            title: this.$t('participant.addresOverride'),
            cancelText: this.$t('confirm.no'),
            confirmText: this.$t('button.delete'),
            onConfirm: async () => {
              this.deleteAddress(address, true);
            }
          });
        } else if (error.data.status === 'Address Is Scheduled Today') {
          const { data } = await this.Api.get(`/participants/${this.participantId}`);
          toast(
            'danger',
            this.$t('participant.addressInUse', {
              driver: data.participant_full_name
            }),
            5000
          );
        } else {
          console.error(error);
        }
      }
      this.loadingAdvanced.delete = null;
    },
    async onSave(confirmed = false) {
      const { file, loaded } = this.$refs.picturePicker;
      if (confirmed || file || (!file && !loaded)) {
        this.loading.save = true;
        if (this.validate()) {
          await this.updateAddress();
          if (!this.address.id) this.resetForm();
          this.isSameAsCompared = true;
        }
        this.loading.save = false;
      } else {
        this.confirmSave();
      }
    },
    onSetDefault(address) {
      confirm({
        message: this.$t('confirms.setDefAddress', [address.full_address]),
        cancelText: 'No',
        confirmText: 'Ok',
        onConfirm: () => {
          this.updateDefault(address.id);
        }
      });
    },
    onShowPosition(address) {
      this.showMap(address, false);
    },
    prepareForMaps({ address, state, city, latitude, longitude, zipcode }) {
      const comp = [];
      if (address) comp.push(address);
      if (city) comp.push(city);
      if (state) comp.push(state);
      if (zipcode) comp.push(`zc ${zipcode}`);
      return {
        address: comp.join(', '),
        latLng: { lat: latitude, lng: longitude }
      };
    },
    resetForm() {
      this.$store.commit('map/update', { action: 'restore' });
      this.address = {
        pictureId: Math.random(),
        address: null,
        address_1: null,
        state: this.$store.getters.STATE,
        city: null,
        zipcode: null
      };
      this.comparedAddress = {};
      this.pictureIndex++;
      this.picturePickerKey = `${this.address.pictureId} ${this.address.id} ${this.pictureIndex}`;
      this.isNewImage = true;
    },
    setPosition({ lat, lng } = {}) {
      this.address.latitude = lat || null;
      this.address.longitude = lng || null;
    },
    showMap(oAdress, pick) {
      const data = this.prepareForMaps(oAdress);
      this.$store.dispatch('map/updateMapWindow', {
        action: pick ? 'getPosition' : 'showPosition',
        data
      });
    },
    updateDefaultAddress() {
      this.defaultAddress = this.addresses.filter((A) => A.is_default === 1)[0] || {};
      this.address = this.defaultAddress;
      this.picturePickerKey = this.address.pictureId || this.address.id;
    },
    updateMapBreadcumbs(clear = false) {
      this.$store.commit('map/breadcumbs', clear ? null : this.breadcumbs);
    },
    async updateDefault(id) {
      if (this.defaultAddress.id !== id) {
        this.loadingAdvanced.default = id;
        try {
          await this.Api.patch(`/participants/${this.participantId}/addresses/${id}`);
          toast('success', this.$t('messages.updated'), 5000);
          this.addresses.map((c) => {
            c.id === id ? (c.is_default = 1) : (c.is_default = 0);
          });
          this.updateDefaultAddress();
          this.emitChange(this.defaultAddress);
        } catch (error) {
          console.error(error);
        }
        this.loadingAdvanced.default = null;
      }
    },
    async updatePicture(imageId) {
      if (this.$refs.picturePicker)
        await this.$refs.picturePicker.save({ fileName: `${imageId}.png` });
      const ref = this.$refs[`addressView${this.address.id}`];
      if (ref) {
        if (ref.updatePresigned) ref.updatePresigned();
        else ref[0].updatePresigned();
      }
    },
    async updateAddress() {
      const { participantId, address } = this;
      const params = {
        name: 'NO NAME',
        address: address.address,
        address_1: address.address_1,
        state: address.state,
        city: address.city,
        zipcode: address.zipcode,
        gate_code: address.gate_code,
        latitude: address.latitude || 0,
        longitude: address.longitude || 0,
        formatted_address: address.formatted_address || ''
      };
      const update = address.id ? `/${address.id}` : ``;
      try {
        const { data } = await this.Api[update ? 'put' : 'post'](
          `/participants/${participantId}/addresses${update}`,
          params
        );
        await this.updatePicture(data?.id);
        if (!address.id) this.addresses.push(data);
        else
          for (let index = 0; index < this.addresses.length; index++) {
            if (this.addresses[index].id == address.id) this.addresses[index] = data;
          }
        this.updateDefaultAddress();
        this.comparedAddress = { ...this.address };
        this.address.pictureId = Math.random();
        toast('success', this.$t('messages.saved'), 5000);
      } catch (error) {
        console.error(error);
      }
    },
    validate() {
      let htmlValidator = true;
      if (this.$refs.addressPicker) htmlValidator = this.$refs.addressPicker.validate();
      return htmlValidator;
    },
    async handleCancelEdit() {
      this.address = { ...this.comparedAddress };
      if (!this.address.id) {
        this.address = { ...this.defaultAddress };
        this.comparedAddress = { ...this.defaultAddress };
      }
      this.isSameAsCompared = true;
      this.pictureIndex++;
      this.picturePickerKey = `${this.address.pictureId} ${this.address.id} ${this.pictureIndex}`;
    },
    handleImageChange() {
      this.isNewImage = true;
      this.isSameAsCompared = false;
    },
    handleAddressLoading(value) {
      this.loading.edit = value;
    }
  },
  props: {
    participantId: {
      type: Number,
      required: true
    }
  }
};
