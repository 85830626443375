<template>
  <Inside-card
    class="contact-viewer flex f-ai f-jc"
    :key="contact.id"
    :margin-bottom="marginBottom"
  >
    <div v-if="loading" class="flex loading">
      <div class="full-contact">
        <b-skeleton height="30" width="100%" />
      </div>
      <div class="actions">
        <b-skeleton height="30" width="30" />
      </div>
    </div>

    <div v-else class="flex contact-form-container f-ai">
      <div class="columns full-contact">
        <b-field class="column" :label="$t('contact.fullName')">
          {{ contact.full_name }}
        </b-field>
        <b-field class="column relation" :label="$t('contact.relation')">
          {{ contact.relationship ? $t(`relationship.${contact.relationship}`) : '-' }}
        </b-field>
        <b-field class="column phone" :label="$t('contact.phone')">
          <Phone-viewer :phone="contact.phone_number" />
        </b-field>
        <b-field class="column phone" :label="$t('contact.mobile')">
          <Phone-viewer :phone="contact.mobile_phone_number" />
        </b-field>
      </div>
      <div class="actions flex">
        <Link
          v-if="$listeners.setDefault"
          :label="$t('button.setDefault.ttp')"
          @click="$emit('setDefault', contact)"
        >
          <b-icon icon="star" />
        </Link>

        <Link v-if="$listeners.edit" :label="$t('button.edit')" @click="$emit('edit', contact)">
          <b-icon icon="square-edit-outline" />
        </Link>
        <Link
          v-if="$listeners.remove"
          :label="$t('button.delete')"
          @click="$emit('remove', contact)"
        >
          <b-icon icon="trash-can-outline" />
        </Link>
      </div>
    </div>
  </Inside-card>
</template>

<script>
import InsideCard from '@/components/InsideCard.vue';
import Link from '@/components/Link';
import PhoneViewer from '@/components/Phone/PhoneViewer.vue';

export default {
  components: {
    InsideCard,
    Link,
    PhoneViewer
  },
  props: {
    contact: { type: Object, required: true },
    loading: { type: Boolean, default: false },
    marginBottom: { type: Number, default: 10 }
  }
};
</script>

<style lang="sass" scoped>
$phone-color-text: #2F76EF

.loading
  width: 100%
  .actions
    .b-skeleton
      margin: 0 1px
.card
  min-height: 100px
.card.contact-viewer
  padding-bottom: 10px
  .column
    padding-left: 5px
    padding-right: 5px
  .contact-form-container
    padding-top: 15px
    width: 100%
    .relation
      max-width: 130px
    .phone
      max-width: 130px
      color: $phone-color-text
  ::v-deep
    margin-bottom: 20px !important
    .card-content
      width: 100%
      display: flex
      align-items: center
      padding-top: 0px
  .full-contact
    width: 100%
    padding: 0px 15px
    align-items: center
  .actions
    width: fit-content
</style>
