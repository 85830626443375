<template>
  <div class="saveButton">
    <Button
      type="is-primary"
      :label="$t('button.save')"
      @click="() => this.$emit('click')"
      v-bind="{ ...$props, ...$attrs }"
    />
  </div>
</template>

<script>
import { Button } from '@/components';
export default {
  components: {
    Button
  }
};
</script>
<style lang="sass" scoped>
.saveButton
  display: flex
  justify-content: flex-end
  width: 100%
</style>
