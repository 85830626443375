<template>
  <section class="flex">
    <div class="add-new">
      <div class="l-title">
        <template v-if="contact.id">
          {{ $t('contact.edit') }}
        </template>
        <template v-else>
          {{ $t('contact.add') }}
        </template>
      </div>
      <ContactPicker ref="contact" :v-model="contact" :value="contact" :edit="true" />
      <div class="flex">
        <b-button v-if="contact.id" @click="contact = {}" type="is-primary">
          {{ $tc('button.add', '') }}</b-button
        >
        <save-button @click="onSave" :loading="loading.save" />
      </div>
    </div>
    <div class="p-contacts">
      <div :class="!loading.getContacts && contacts.length <= 0 ? 'hide' : ''">
        <div class="l-title">
          {{ $t('contact.registered') }}
        </div>
        <div v-if="!loading.getContacts && contacts.length && !defaultContact.id">
          MISSING DEFAULT CONTACT
        </div>
        <ContactViewer
          :ref="`contactView${defaultContact.id}`"
          v-else
          :contact="defaultContact"
          :loading="loading.getContacts"
          @edit="onEdit"
        />
        <div class="c-content">
          <template v-for="contact in contacts">
            <ContactViewer
              :key="contact.id"
              :ref="`contactView${contact.id}`"
              v-if="contact.id != defaultContact.id"
              :contact="contact"
              :loading="loading.getContacts"
              @setDefault="onSetDefault"
              @edit="onEdit"
            />
          </template>
        </div>
      </div>
      <div
        v-if="!loading.getContacts && contacts.length < 1"
        class="empty-contacts-container flex f-ai f-jc"
      >
        <img src="@/assets/img/emptyContacts.svg" />
        <p class="empty-text">{{ $t('contact.emptyContacts') }}</p>
      </div>
    </div>
  </section>
</template>

<script>
import ContactPicker from '@/components/Contact/ContactPicker.vue';
import ContactViewer from '@/components/Contact/ContactViewer.vue';
import saveButton from './saveButton.vue';
import { toast, confirm } from '@/utils/dialog';

export default {
  mounted() {
    this.getContacts();
    this.$store.dispatch('setBreadcumbs', this.breadcumbs);
  },
  components: {
    ContactPicker,
    ContactViewer,
    saveButton
  },
  data() {
    return {
      loading: { save: false, getContacts: true },
      contact: {},
      defaultContact: {},
      contacts: []
    };
  },
  computed: {
    breadcumbs() {
      return [
        'menu.dat',
        this.participantId ? 'participant.edit' : 'participant.new',
        'global.contacts'
      ];
    }
  },
  methods: {
    contactBy(key, value) {
      return this.contacts.filter((A) => A[key] == value);
    },
    async getContacts() {
      this.loading.getContacts = true;
      try {
        let { data } = await this.Api.get(
          `/participants/${this.participantId}/contacts?per_page=500`
        );
        this.contacts = data;
        this.updateDefaultContact();
        this.contact = this.defaultContact;
      } catch (error) {
        console.error(error);
      }
      this.loading.getContacts = false;
    },
    onSetDefault(contact) {
      confirm({
        message: this.$t('confirms.setDefContact', [
          `${contact.last_name}, ${contact.first_name} ${contact.middle_name}`
        ]),
        cancelText: 'No',
        confirmText: 'Ok',
        onConfirm: () => {
          this.updateDefault(contact);
        }
      });
    },
    async updateDefault(contact) {
      const { participantId } = this;
      this.loading.default = true;
      try {
        await this.Api.patch(`/participants/${participantId}/contacts/${contact.id}`);
        toast('success', this.$t('messages.updated'), 5000);
        this.contacts.map((c) => {
          c.id === contact.id ? (c.is_default = 1) : (c.is_default = 0);
        });
        this.updateDefaultContact();
      } catch (error) {
        console.error(error);
      }
      this.loading.default = false;
    },
    updateDefaultContact() {
      this.defaultContact = this.contacts.filter((c) => c.is_default === 1)[0] || {};
    },
    async save() {
      const { contact, participantId } = this;
      if (contact) {
        const params = {
          address: '-',
          city: '-',
          first_name: contact.first_name,
          last_name: contact.last_name,
          middle_name: contact.middle_name || '',
          mobile_phone_number: contact.mobile_phone_number,
          phone_number: contact.phone_number,
          primary_phone_number: '0',
          relationship: contact.relationship,
          state: 'CA',
          zipcode: '00000'
        };

        const update = contact.id ? `/${contact.id}` : ``;
        try {
          const { data } = await this.Api[update ? 'put' : 'post'](
            `/participants/${participantId}/contacts${update}`,
            params
          );
          toast('success', this.$t('messages.saved'), 5000);
          if (!contact.id) {
            this.contacts.push(data);
            this.contact = {};
          } else
            for (let index = 0; index < this.contacts.length; index++) {
              if (this.contacts[index].id == contact.id) this.contacts[index] = data;
            }
          this.updateDefaultContact();
          // this.contact = {};
        } catch (error) {
          console.error(error);
        }
      }
    },
    async onSave() {
      this.loading.save = true;
      if (this.validate()) await this.save();
      this.loading.save = false;
    },
    async onEdit(contact) {
      this.contact = contact;
    },
    validate() {
      let htmlValidator = true;
      if (this.contact) htmlValidator = this.$refs.contact.validate();
      return htmlValidator;
    }
  },
  props: {
    participantId: { type: Number, default: 0 }
  }
};
</script>

<style lang="sass" scoped>
.p-contacts
  width: 55%
  margin-left: auto
  margin-top: 25px
::v-deep .card-header
  font-size: 18px
  font-weight: bold
  text-transform: initial
  box-shadow: none
.c-content
  padding: 0 10px
  margin: 0 -10px
  max-height: 480px
  min-height: 300px
  overflow: auto
.l-title
  font-size: $f-xl
  font-weight: bold
  margin-bottom: 20px
.add-new
  padding: 10px
  margin-right: 30px
  width: 40%
  form.columns
    display: grid
    grid-gap: 5px
    grid-template-columns: repeat(3, 1fr)
  .saveButton
    margin-top: 15px
.empty-contacts-container
  transition: $transition-dark-mode
  background: $main-background
  margin-left: auto
  border-radius: 10px
  width: 100%
  padding: 5%
  flex-direction: column
  .empty-text
    text-align: center
    text-transform: initial
    margin-top: 20px
.hide
  display: none
::v-deep .p-contacts
  .field
    margin-bottom: 0px
    padding-bottom: 0px
</style>
